.progressBar {
  position: relative;
  height: 6px;
  border-radius: 3px;
  background-color: var(--color-neutral-200);
  overflow: hidden;
}

.progress {
  display: block;
  position: absolute;
  background-color: var(--color-primary-base);
  height: 100%;
  left: 0;
  top: 0;
  transition: width .3 ease;
}