.textArea {
    width: 100%;
    box-sizing: border-box;
    border: none;
    text-align: left;
    display: block;
    font-size: var(--font-size-md);
    outline: none;
    text-overflow: ellipsis;
    z-index: 1;
    font-family: var(--font-family-body);
    border-radius: 4px;

    &::placeholder {
        transition: 0.1s color;
        color: var(--color-grey-500);
        text-overflow: ellipsis;
    }

    &:disabled {
        background: none;
    }

    &.withLabel {
        &::placeholder {
            color: transparent;
        }
    }
}

.textAreaField {
    min-height: 130px;
    background-color: var(--color-neutral-white);
    line-height: var(--line-height-single);
    padding: var(--spacing-3) 0 0 var(--spacing-3);
    border: 1px solid var(--color-neutral-500);
    border-color: var(--color-danger);
    position: relative;
    display: flex;
    cursor: type;
    border-radius: 4px;

    & [class*='label'] {
        top: 1.8em;
    }

    &.valid {
        border-color: var(--color-neutral-500);
    }
}

.textAreaFieldWrapper {
    position: relative;
    opacity: 1;
    color: var(--color-danger);

    &.disabled {
        opacity: 0.3;
    }

    &.valid {
        color: var(--color-grey-400)
    }
}