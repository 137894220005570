.root {
    width: 100%;
    display: flex;
    justify-content: center;
}

.errorBox {
    max-width: 500px;
    width: 100%;
    text-align: center;
    border: 1px solid var(--color-danger);
    color: var(--color-danger);
}

.large {
    composes: errorBox;
    padding: var(--spacing-4);
    font-size: var(--font-size-2xl);
}

.small {
    composes: errorBox;
    padding: var(--spacing-2);
    font-size: var(--font-size-md);
}