.root {
    width: 100%;
    background-color: var(--color-neutral-100);
    border-top: 1px solid var(--color-neutral-300);
    border-bottom: 1px solid var(--color-neutral-300);
    margin-bottom: var(--spacing-5);
}

.rootWithPadding {
    composes: root;
    padding: var(--spacing-6) 0;
}
