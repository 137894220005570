.alignContent {
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    gap: 15px;
}

.alignContent [class*='text'],
.alignContent [class*='richText'],
.alignContent p {
    color: inherit;
    max-width: 500px;
}

.alignmentLeft {
    align-items: flex-start;
}
.alignmentRight {
    align-items: flex-end;
    text-align: right;
}
.alignmentCenter {
    align-items: center;
    text-align: center;
}

.positionTop {
    justify-content: flex-start;
}
.positionCenter {
    justify-content: center;
}
.positionBottom {
    justify-content: flex-end;
}
