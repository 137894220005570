.root {
    position: relative;
}

.dropdownButtonWrapper {
    position: relative;
}

.skeleton {
    position: absolute;
    opacity: .8;
    left: 0;
    top: 0;
}

.optionsList {
    composes: scrollBarMedium from global;
    width: 100%;
    position: relative;
    border: 1px solid var(--color-line);
    margin: 0;
    padding: 0;
    position: absolute;
    top: calc(100% + var(--spacing-1));
    left: 0;
    width: 100%;
    background-color: var(--color-neutral-white);
    z-index: var(--z-index-max);
    max-height: 300px;
    overflow: auto;
    &:focus {
        outline: none;
    }
}

.optionsListItem {
    border-bottom: 1px solid var(--color-line);
}

.optionButton {
    cursor: pointer;
    border: none;
    border-radius: 0;
    background-color: transparent;
    width: 100%;
    padding: var(--spacing-2);
    display: flex;
    gap: var(--spacing-2);
    align-items: center;

    &:disabled {
        color: var(--color-neutral-500);
        font-weight: var(--font-weight-light);
        cursor: not-allowed;

        & .checkBoxChecked {
            background-color: var(--color-neutral-500);
        }
    }
}

.labelWrapper {
    width: 100%;
    gap: var(--spacing-2);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.checkBox {
    display: block;
    width: var(--spacing-3);
    min-width: var(--spacing-3);
    height: var(--spacing-3);
    border: 1px solid var(--color-neutral-500);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.checkBoxChecked {
    display: block;
    width: var(--spacing-2);
    height: var(--spacing-2);
    background-color: var(--color-neutral-700);
}