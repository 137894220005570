.headerWrapper {
    display: flex;
    align-items: flex-start;
}

.headerCheckbox {
    position: relative;
    top: 5px;
}

.header {
    border-bottom: 1px solid var(--color-line);
    padding-bottom: var(--spacing-1);
    margin-bottom: var(--spacing-2);
}

.listItem {
    position: relative;
    margin-bottom: 2px;
    padding: 0;
    border: none;

    & > button {
        padding: var(--spacing-1) 0;
        & > div > p {
            font-size: var(--font-size-sm);
        }

        & + div > div {
            padding: 0 0 0 0;
        }
    }
}

.subList {
    margin: var(--spacing-1) 0;
}

.skeletonListItem {
    composes: listItem;
    margin: var(--spacing-2) 0;
    display: flex;
    gap: var(--spacing-2);
    justify-content: space-between;
}

.skeletonButtonItem {
    position: absolute;
    opacity: .7;
    left: 0;
    top: 0;
   
}

.subListItem {
    position: relative;
    margin-bottom: 3px;
}

.button {
    composes: cleanButton from global;
    padding: var(--spacing-1);
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    gap: var(--spacing-1);
    padding-left: 0;
    margin-bottom: .5px;
    
    &:not(:disabled) {
        cursor: pointer;
    }

    &:disabled {
        opacity: .5;
    }
}

.subCategoryText {
    flex: 1;
}

.sidebarCategoryHeader {
    display: flex;
    justify-content: space-between;
}