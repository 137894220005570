.price {
    color: var(--color-neutral-black);
    font-weight: var(--font-weight-bold);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: var(--font-size-md);
}

.modifierOriginal {
    text-decoration: line-through;
}

.modifierDiscounted {
    color: #da3831; /* TODO Variable */
}

.priceGroup {
    display: flex;
}

.priceGroupHorizontal {
    gap: 15px; /* TODO Variable */
}

.priceGroupVertical {
    flex-direction: column;
    align-items: flex-end;

}

.priceUnit {
    font-weight: var(--font-weight-regular);
    text-align: right;
}
