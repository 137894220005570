.richText {
    color: #000000; /* TODO Variable */

    & img {
        max-width: 100%;
        height: auto;
    }

    & a {
        color: var(--color-focus);
        text-decoration: underline;
    }

    & p,
    & ol,
    & ul,
    & pre,
    & table {
        margin-bottom: 15px; /* TODO Variable */
    }

    & p {
        min-height: 1em;
    }

    & li {
        margin-bottom: 20px; /* TODO Variable */
    }

    & ol {
        padding-left: 25px;
        list-style: decimal;
    }

    & ul {
        padding-left: 25px;
        list-style: disc;
    }

    & code {
        font-family: 'monospace'; /* TODO Variable */
    }

    & pre {
        background-color: #f8f7f7; /* TODO Variable */
        padding: 15px;
        font-family: 'monospace'; /* TODO Variable */
    }

    & [class*='text'] {
        color: inherit;
    }
}

.table {
    min-width: 100%;
    border-collapse: collapse;

    &:td,
    &:th {
        padding: 0.75em;
        text-align: left;
        border: 1px solid #f8f7f7; /* TODO Variable */
        box-sizing: border-box;
    }

    &:th {
        font-weight: 700; /* TODO Variable */
        background-color: #f8f7f7; /* TODO Variable */
        font-size: 14px; /* TODO Variable */
    }

    &:thead {
        position: sticky;
        top: 0;
        background-color: #ffffff;
    }

    &:tbody > tr > th:first-child {
        position: sticky;
        left: 0;
    }
}
