.wrapper {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapperFixed {
    composes: wrapper;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    flex-direction: column;
    align-items: center;
    background-color: rgba(var(--color-neutral-black), 0.7);
    z-index: var(--z-index-max);
    pointer-events: none;
}

.loader {
    border: 5px solid transparent;
    border-top: 5px solid var(--color-focus);
    border-right: 5px solid var(--color-focus);
    border-bottom: 5px solid var(--color-focus);
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
