html {
    box-sizing: border-box;
    overflow-y: scroll;
    height: -webkit-fill-available;
    background-color: var(--color-neutral-white);
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    height: 100vh;
    /* mobile viewport bug fix */
    height: -webkit-fill-available;
    font-family: var(--font-family-body);
    line-height: var(--line-height-base);
    margin: 0;
}

ol,
ul {
    list-style: none;
    padding-left: 0;
}

/* Remove arrows on input number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}
