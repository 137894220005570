.root {
    margin: var(--spacing-5) auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

.progress {
    width: 100%;
    max-width: 300px;
}