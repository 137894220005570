.productList {
    container-type: inline-size;
}

.header {
    display: flex;
    gap: var(--spacing-4);
    justify-content: space-between;
    border-bottom: 1px solid var(--color-line);
    padding-bottom: var(--spacing-1);
}

.headerLeft {
    display: flex;
    gap: var(--spacing-4);
    align-items: center;
}

.headerRight {
    display: flex;
    gap: var(--spacing-4);
    align-items: center;    
}

.skeletonItem {
    padding: var(--spacing-4) 0;
}

.plpTitle {
    white-space: nowrap;
}
