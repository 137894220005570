.summary {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 0.5em;
}

.summaryText {
    color: var(--color-neutral-black);
}

.summaryDivider {
    height: 1px;
    background-color: var(--color-neutral-300);
    margin: var(--spacing-3) auto;
}

.descriptionTableData {
    vertical-align: middle;
}

.summaryTableData {
    color: var(--color-neutral-700);
    text-align: right;
    vertical-align: middle;
    white-space: nowrap;

    .discount {
        color: var(--color-primary-600);
    }
}
