.root {
    display: inline-block;
    position: relative;
    overflow: hidden;
    border-radius: var(--border-radius-small);

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        animation: shimmer 2s infinite;
        background-image: linear-gradient(
            90deg, 
            rgba(255, 255, 255, 0) 0, 
            rgba(255, 255, 255, 0.5) 50%, 
            rgba(255, 255, 255, 0) 
        );
    }
}

.light {
    composes: root;
    background-color: var(--color-neutral-300);
}

.darker {
    composes: root;
    background-color: var(--color-neutral-400);
}

@keyframes shimmer {
    100% { transform: translateX(100%);}
}