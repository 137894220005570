.dropdownContainer {
    position: relative;
    display: inline-block;
}

.dropdownTrigger {
    font-family: var(--font-family-body);
    font-size: var(--font-size-sm);
    padding: 0 var(--spacing-2);
    &:focus-visible {
        outline: 1px solid var(--color-focus);
    }

    & svg {
        pointer-events: none;
    }
}

.dropdownTitle {
    margin-right: var(--spacing-2);
    font-family: var(--font-family-body);
    font-size: var(--font-size-sm);
    cursor: pointer;
}

.dropdownList {
    z-index: var(--z-index-1);
    position: absolute;
    background-color: var(--color-neutral-white);
    border: 1px solid var(--color-neutral-black);
    border-radius: 4px;
    min-width: 100%;
    margin: var(--spacing-1) 0;
}

.listItem {
    &:hover {
        background-color: var(--color-neutral-200);
    }
    &:focus {
        outline: none;
        background-color: var(--color-neutral-200);
    }

    &:first-of-type {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    &:last-of-type {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    cursor: pointer;
}

.listOption {
    font-family: var(--font-family-body);
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-regular);
    padding: var(--spacing-3) var(--spacing-2);
    min-width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
    &:focus {
        outline: none;
        background-color: var(--color-grey-300);
    }

    &.selected {
        font-weight: var(--font-weight-bold);
    }
}