.buttonIsLoading {
    & > div {
        justify-content: left;
        left: 9px;
    }

    &:disabled > span {
        color: var(--color-neutral-black);
        opacity: 0.8;
    }
}