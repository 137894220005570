.root {
    display: flex;
    align-items: center;
    gap: 10px;
}

.status {
    display: inline-block;
    border-radius: 100%;
    width: 12px;
    height: 12px;
    flex-shrink: 0;
}

.watchStock {
    composes: cleanButton from global;
    cursor: pointer;
    padding: var(--spacing-1);
    display: flex;
    gap: var(--spacing-2);
    align-items: center;
    border-radius: var(--border-radius-small);

    &:hover {
        background-color: var(--color-neutral-200);
    }

    &:disabled {
        & .icon {
            opacity: .3;
        }
    }
}

.status0 {
    composes: status;
    background-color: var(--color-danger);
}

.status1, .status2 {
    composes: status;
    background-color: var(--color-success);
}

.status3 {
    composes: status;
    background-color: var(--color-attention);
}

/* .textColor0 { color:  var(--color-danger)} */
/* .textColor1, .textColor2 { color: var(--color-success)} */
/* .textColor3 {color: var(--color-attention)} */