.headerText {
    display: flex;
    justify-content: flex-start;
    padding-left: 0px;
    padding: var(--spacing-4) 0;
    @media --md {
        padding-left: var(--spacing-7);
    }

    & > p:first-of-type {
        width: 150px;
    }
}

.accordionText button {
    word-spacing: 85px;
    padding-left: 0px;
    @media --md {
        padding-left: var(--spacing-4);
    }
}
.accordionText button P {
    font-size: var(--font-size-sm);
}

.resWrapper {
    margin-top: var(--spacing-4);
}

.orderHeader {
    display: flex;

    & > p:first-of-type {
        width: 150px;
    }
}
