.content {
    max-width: 350px;
    z-index: var(--z-indix-max);
}

.trigger {
    display: inline-block;
}

.contentWrapper {
    display: inline-block;
    background-color: var(--color-neutral-300);
    padding: var(--spacing-2) var(--spacing-2);
}

.fallbackIcon {
    border-radius: 100%;
    background-color: var(--color-neutral-300);
    width: 15px;
    height: 15px;
    display: inline-block;

    & path {
        stroke: transparent;
        fill: var(--color-white);
    }
}