.overlayWrapper {
    position: relative;
    z-index: var(--z-index-max);
    overflow-x: hidden;

    &.slide {
        @media --sm {
            padding-left: var(--spacing-1); /* FIX quirk with spring animations going outside overflow */
        }
    }
}

.backdrop {
    position: fixed;
    inset: 0 0 0 0;
    background: rgba(0, 0, 0, 0.25);
    cursor: pointer;
    z-index: -1;
}

.header {
    position: sticky;
    z-index: var(--z-index-1);
    top: 0;
    height: var(--spacing-8);
    display: flex;
    align-items: center;
    padding-left: var(--spacing-3);
    padding-right: var(--spacing-1);
    justify-content: space-between;

    &.solid {
        border-bottom: 1px solid var(--color-line);
        background: var(--color-neutral-white);
    }

    &.full {
        /* Absolute positioning does not work inside scroll, so we simply let sticky do its thing.
        By adding minus margin, we make this element move out of screen (and therefore removing whitespace)
        while sticky makes sure it is still visible. */
        margin-top: -var(--spacing-8);
    }
}

.closeText {
    margin-left: var(--spacing-2);
    vertical-align: text-bottom;
}

.overlay {
    overflow-y: auto;
    max-height: 100vh;
    background: var(--color-neutral-white);

    &.full {
        @mixin cover;
        position: fixed;
        height: stretch;
    }

    &.minimal {
        position: fixed;
        top: 50%;
        left: 50%;
        max-height: 90vh;
        width: fit-content;
        outline: 1px solid var(--color-neutral-black); /* Outline works better than border with the animation */
        @media --md {
            width: 100%;
            max-width: clamp(600px, 5vw, 900px);
            max-height: clamp(600px, 5vw, 900px);
        }
    }

    &.fadeSlide {
        position: sticky;
        right: 0;
        left: 0;
        top: 0;
        border-bottom: 1px solid var(--color-neutral-black);
        @media --md {
            border-top: 1px solid var(--color-neutral-black);
            border-left: 1px solid var(--color-neutral-black);
            border-right: 1px solid var(--color-neutral-black);
            padding-left: var(--spacing-1); /* FIX quirk with spring animations going outside overflow */
        }
    }

    &.page {
        position: fixed;
        inset: 0;
        margin: auto;
        @media --md {
            max-width: min(88vw, 1690px);
            max-height: 90vh;
        }
    }
}