.helpText {
    font-size: var(--font-size-2xs);
    color: var(--color-grey-300);
    margin-top: 8px;

    &.invalidMessage {
        color: var(--color-danger);
        & + [class*='helpText'] {
            display: none;
        }
    }
}