.root {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: var(--spacing-2);
    margin-top: var(--spacing-2);
}

.controls {
    display: grid;
    row-gap: var(--spacing-1);
    
    @media --sm {
        display: flex;
        align-items: center;
        column-gap: var(--spacing-2);
    }
}

.select {
    border: 1px solid var(--color-neutral-400);
    padding: var(--spacing-1) var(--spacing-2);
    color: var(--color-neutral-900);
    border-radius: var(--border-radius-small);
    background-color: transparent;
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-light);

    &:disabled {
        border: 1px solid var(--color-grey-400);
        color: var(--color-grey-500);
    }
}