.root {
    display: flex;
    justify-content: space-between;
}

.buttonWrapper {
    position: relative;
}

.skeleton {
    /* border: 1px solid red; */
    opacity: .5;
    position: absolute;
    top: 0;
    left: 0;
}

.facetsWrapper {
    display: flex;
    width: 100%;
    gap: var(--spacing-2);
    flex-wrap: wrap;
}