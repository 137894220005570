.flexWrapper {
    display: grid;
    gap: var(--spacing-6);
    @media --md {
        grid-template-columns: 1fr 1fr;
    }
    margin-bottom: var(--spacing-6);
}


.overviewWrapper {
    border: 1px solid var(--color-neutral-300);
    padding: var(--spacing-4);
    background-color: var(--color-neutral-white);
}

.saldoWrapper {
    composes: overviewWrapper;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: var(--spacing-4);
}

.listItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: var(--spacing-2);
    padding-bottom: var(--spacing-2);
}

.gridItem {
    border-bottom: 1px solid var(--color-neutral-300);
    list-style: none;
    padding-top: var(--spacing-3);
    padding-bottom: var(--spacing-3);
    &:last-child {
        border: none;
    }
}

.contact {
    display: flex;
    border-bottom: 1px solid var(--color-neutral-300);
    padding-bottom: var(--spacing-3);
    &:last-child {
        border: none;
    }
}

.overviewHeader {
    display: flex;
    align-items: center;
}

.logoutButton {
    height: 48px;
    padding: 0 var(--spacing-2);
    gap: 0;
}

.logoutText {
    padding: 0 0 0 var(--spacing-2);
    display: none;

    @media --md {
        display: block;
    }
}
.resWrapper {
    margin-top: var(--spacing-4);
}

/* 
TODO remove if no image 
.contact img {
    border: 1px solid var(--color-neutral-300);
    margin-right: var(--spacing-4);
} */
.price {
    display: none;
    @media --sm {
        display: block;
    }
}
