.root {
    display: flex;
    align-items: stretch;
    width: auto;
    position: relative;

    &:focus-within {
        outline: 2px solid #348DF7;
        border-radius: 4px;
    }
}

.inputWrapper {
    z-index: 1;
    position: relative;
    padding: 5px;

    border-top: 1px solid var(--color-neutral-300);
    border-bottom: 1px solid var(--color-neutral-300);

}

.inputWrapperSmall {
    composes: inputWrapper;
    font-size: var(--font-size-xs);
    min-width: var(--spacing-5);
    padding: 3px;
}

.inputWrapperMedium {
    composes: inputWrapper;
    font-size: var(--font-size-sm);
    min-width: var(--spacing-6);
    padding: 5px;
}

.input {
    border-radius: 0;
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: none;
    appearance: none;
    font-size: inherit;
    border-radius: 0;
    outline: none;
}

.inputLabel {
    composes: srOnly from global;
}

.button {
    z-index: 2;
    padding: 0;
}

.minusButton.button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-color: var(--color-neutral-300);

    & > div {
        display: flex;
        align-items: center;
    }
}

.plusButton.button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-color: var(--color-neutral-300);

    & > div {
        display: flex;
        align-items: center;
    }
}
