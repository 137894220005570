.label {
    position: absolute;
    top: 1.4em;
    font-size: var(--font-size-md);
    transition: 0.1s transform, 0.1s font-size;
    transform: translateY(-50%);
    color: var(--color-neutral-500);
    background-color: var(--color-neutral-white);
    max-width: 100%;
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transform-origin: left top;
    pointer-events: none;
    padding: 0 var(--spacing-2);
    z-index: 2;

    &.active {
        font-size: var(--font-size-xs);
        transform: translateY(-2.3em);
    }

    &.focus {
        color: var(--color-neutral-black);
    }

    &.invalid {
        color: var(--color-danger);
    }
}