/**
 * Do not edit directly
 * Generated on Fri, 29 Sep 2023 07:02:01 GMT
 */

:root {
  --border-radius-small: 3px;
  --border-radius-medium: 6px;
  --border-radius-large: 9px;
  --border-radius-round: 50%;
  --color-neutral-100: #FAFBFC;
  --color-neutral-200: #F1F4F8;
  --color-neutral-300: #E4E9F2;
  --color-neutral-400: #ABB4C5;
  --color-neutral-500: #8591AB;
  --color-neutral-700: #364960;
  --color-neutral-900: #182F46;
  --color-neutral-white: #FFFFFF;
  --color-neutral-black: #101426;
  --color-grey-50: #FAFAFA;
  --color-grey-100: #F5F5F5;
  --color-grey-200: #E8E8E8;
  --color-grey-300: #D6D6D6;
  --color-grey-400: #A6A6A6;
  --color-grey-500: #777777;
  --color-grey-600: #575757;
  --color-grey-700: #444444;
  --color-grey-800: #333333;
  --color-grey-900: #1A1A1A;
  --color-body: #101426;
  --color-primary-500: #FF2F37;
  --color-primary-600: #DB2239;
  --color-primary-base: #FF6A63;
  --color-line: #E4E9F2;
  --color-meta: #101426;
  --color-success: #50AC86;
  --color-attention: #FFB700;
  --color-danger: #D02828;
  --color-focus: #348DF7;
  --spacing-1: 5px;
  --spacing-2: 10px;
  --spacing-3: 15px;
  --spacing-4: 20px;
  --spacing-5: 30px;
  --spacing-6: 40px;
  --spacing-7: 50px;
  --spacing-8: 60px;
  --spacing-9: 70px;
  --spacing-10: 80px;
  --spacing-gutter: min(6vw, 115px);
  --z-index-1: 100;
  --z-index-2: 200;
  --z-index-3: 300;
  --z-index-4: 400;
  --z-index-max: 999;
  --font-family-headline: Helvetica, Arial, sans-serif;
  --font-family-body: Helvetica, Arial, sans-serif;
  --font-family-mono: Helvetica, Arial, sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 500;
  --font-weight-semi-bold: 700;
  --font-weight-bold: bold;
  --line-height-single: 1;
  --line-height-compact: 1.15;
  --line-height-base: 1.5;
  --line-height-headline: 1.1;
  --font-size-2xs: 10px;
  --font-size-xs: 12px;
  --font-size-sm: 14px;
  --font-size-md: 16px;
  --font-size-lg: 18px;
  --font-size-xl: 21px;
  --font-size-2xl: 30px;
  --font-size-3xl: 35px;
  --font-size-4xl: 40px;
  --font-size-5xl: 45px;
  --font-size-6xl: 50px;
  --font-size-7xl: 55px;
  --font-size-h6: 16px;
  --font-size-h5: 20px;
  --font-size-h4: 24px;
  --font-size-h3: 32px;
  --font-size-h2: 40px;
  --font-size-h1: 48px;
}
