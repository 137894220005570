.gridRoot {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: var(--spacing-1);
}

.divItem {
    display: grid;
    flex: 1;
    grid-template-columns: repeat(2, 1fr);
    padding: var(--spacing-4);
    &:nth-child(odd) {
        background-color: var(--color-neutral-200);
    }
    @media --sm {
        grid-template-columns: repeat(4, 1fr);
        padding: 0px;
        &:nth-child(odd) {
            background-color: var(--color-neutral-white);
        }
        &:nth-child(even) {
            background-color: var(--color-neutral-200);
        }
    }
}

.divItem .partNo,
.divItem .description,
.divItem .quantity,
.divItem .customer {
    display: block;
    @media --sm {
        display: none;
    }
}
.gridRoot .divItem:first-of-type .partNo,
.gridRoot .divItem:first-of-type .description,
.gridRoot .divItem:first-of-type .quantity,
.gridRoot .divItem:first-of-type .customer {
    @media --sm {
        display: block;
        background-color: var(--color-neutral-200);
    }
}

.item {
    margin: 0;
    padding: 0;
    @media --sm {
        padding: var(--spacing-2);
    }
}

.partNo {
    grid-column: 1;
    font-size: var(--font-size-xs);
    @media --sm {
        font-weight: var(--font-weight-bold);
        grid-column: 1;
    }
}

.description {
    grid-column: 2;
    font-size: var(--font-size-xs);
    @media --sm {
        font-weight: var(--font-weight-bold);
        grid-column: 2;
    }
}

.quantity {
    grid-column: 1;
    grid-row: 3/4;
    font-size: var(--font-size-xs);
    margin-top: var(--spacing-2);
    @media --sm {
        font-weight: var(--font-weight-bold);
        grid-row: 1;
        grid-column: 3;
        margin: 0;
    }
}

.customer {
    grid-column: 2;
    grid-row: 3/4;
    font-size: var(--font-size-xs);
    margin-top: var(--spacing-2);
    @media --sm {
        font-weight: var(--font-weight-bold);
        grid-row: 1;
        grid-column: 4;
        margin: 0;
    }
}

.partData {
    grid-column: 1;
    grid-row: 2/3;
    font-size: var(--font-size-sm);
    @media --sm {
        grid-row: revert;
        grid-column: 1;
    }
}
.partDesc {
    grid-column: 2;
    grid-row: 2/3;
    font-size: var(--font-size-sm);
    @media --sm {
        grid-row: revert;
        grid-column: 2;
    }
}
.partQty {
    grid-column: 1;
    font-size: var(--font-size-sm);
    @media --sm {
        grid-column: 3;
    }
}
.partCustom {
    grid-column: 2;
    font-size: var(--font-size-sm);
    @media --sm {
        grid-column: 4;
    }
}
