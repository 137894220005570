.root {
    display: grid;
    grid-template-columns: 75px 1fr;
    align-items: flex-start;
    gap: var(--spacing-4);
}

.productName {
    margin-bottom: 0;
    line-height: 1.2;
    margin-bottom: var(--spacing-1);
}

.description {
    display: grid;
    max-width: 320px;
}

.flexInfo {
    display: flex;
    gap: var(--spacing-1);
}

.expressInfo {
    display: flex;
    gap: var(--spacing-2);
    align-items: center;
    margin-top: var(--spacing-1);
}