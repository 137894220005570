.inputField {
    height: 54px;
    background-color: var(--color-neutral-white);
    border: 1px solid var(--color-neutral-500);
    line-height: var(--line-height-single);
    position: relative;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr;
    cursor: type;
    border-radius: 4px;
}

.input {
    outline: none;
    border: none;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    display: block;
    padding: var(--spacing-3) var(--spacing-4);
    font-size: var(--font-size-sm);
    border-radius: 4px;

    &:focus-visible {
        outline: 2px solid #348DF7;
    }

    &::placeholder {
        transition: 0.1s color;
        color: var(--color-grey-500);
    }

    &.withLabel {
        padding: 25px var(--spacing-4) 5px;
        &::placeholder {
            color: transparent;
        }
    }

    &.activeWithLabel {
        &::placeholder {
            color: transparent;
        }
    }
}

.label {
    position: absolute;
    top: 50%;
    left: var(--spacing-4);
    font-size: var(--font-size-md);
    transition: 0.1s transform, 0.1s width;
    transform: translateY(-50%);
    width: calc(100% - var(--spacing-4) - var(--spacing-4));
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transform-origin: left top;
    pointer-events: none;
    z-index: 2;
    color: var(--color-grey-500);
}

.labelHasFocus {
    color: var(--color-neutral-900);
}

.labelInvalid,
.labelInvalid.labelHasFocus {
    color: var(--color-danger);
}

.labelActive {
    transform: scale(0.75) translateY(-24px);
    width: 110%;
}

.inputFieldWrapper {
    position: relative;

    &:active .label {
        color: var(--color-neutral-900);
    }

    &:active .label.labelInvalid {
        color: var(--color-danger);
    }
}

.additionalContent {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: var(--spacing-2);
    font-size: var(--font-size-2xl);
    pointer-events: none;

    & svg {
        overflow: visible;
    }
}
