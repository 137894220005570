.lineItem {
    position: relative;
    align-items: center;
    background-color: var(--color-neutral-white);
    padding: var(--spacing-3) 0;
    border-bottom: 1px solid var(--color-neutral-300);
    display: grid;
    grid-template-areas:
        "info info"
        "stepper price"
        "stock favorites";
    column-gap: var(--spacing-3);
    row-gap: var(--spacing-4);

    &:first-of-type {
        border-top: 1px solid var(--color-neutral-300);
    }

    @media --basket-md {
        grid-template-areas:
            "info favorites stepper price"
            "info stock stepper price";
            grid-template-columns: 1fr 280px 150px 100px;
        column-gap: var(--spacing-5);
        row-gap: 0;
        padding: var(--spacing-5) 0;
    }

}

.lineFavorites {
    position: relative;
    left: -5px;
    grid-area: favorites;
    display: flex;
    gap: var(--spacing-2);
    align-items: center;
    white-space: nowrap;
    justify-content: flex-end;

    @media --basket-md {
        justify-content: flex-start;
    }
}

.lineStepper {
    grid-area: stepper;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2);
    align-items: flex-start;
}

.dispensableText {
    @media --basket-isolated {
        display: none;
    }
}

.productLink {
    grid-area: info;
    text-decoration: none;
    color: inherit;
}

.linePrice {
    grid-area: price;
    display: flex;
    justify-content: flex-end;
    min-width: 100px;
}