.button {
    composes: cleanButton from global;
    cursor: pointer;
    padding: var(--spacing-1);
    display: flex;
    gap: var(--spacing-2);
    align-items: center;
    border-radius: var(--border-radius-small);

    &:hover {
        background-color: var(--color-neutral-200);
    }

    &:disabled {
        & .icon {
            opacity: .3;
        }
    }
}

.iconWrapper {
    position: relative;
    width: 25px;
    height: 25px;
}

.loader {
    position: absolute;
    left: 0;
    top: 0;
}

.icon {
    stroke: var(--color-neutral-900);
    width: 24px;
    height: 24px;
}

.iconSelected {
    composes: icon;
    fill: var(--color-neutral-900);
}

