/**
 * Do not edit directly
 * Generated on Fri, 29 Sep 2023 07:02:01 GMT
 */

:root .hovwdiaudi-theme {
  --color-neutral-100: #E5ECF2;
  --color-neutral-200: #CCD9E6;
  --color-neutral-300: #B2C5D9;
  --color-neutral-400: #99B3CD;
  --color-neutral-500: #809FC0;
  --color-neutral-700: #4D79A7;
  --color-neutral-900: #1A538E;
  --color-neutral-white: #FFFFFF;
  --color-neutral-black: #000000;
  --color-body: #000000;
  --color-line: #B2C5D9;
  --color-meta: #014082;
}
