.root {
    display: grid;
    gap: var(--spacing-4);
    border-bottom: 1px solid var(--color-neutral-300);
    padding: var(--spacing-2) 0;
    margin-bottom: var(--spacing-2);
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 
        "base-product-info base-product-info"
        "stock-status price"
        "add-favorite add-to-basket"

}

.productLink {
    color: inherit;
    display: block;
    text-decoration: none;
} 

.baseProductInfo {
    grid-area: base-product-info;
}

.price {
    grid-area: price;
}

.stockStatus {
    grid-area: stock-status;
}

.addToBasket {
    grid-area: add-to-basket;
}

.addFavorite {
    grid-area: add-favorite;
}

@container (min-width: 900px) {
    .root {
        grid-template-columns: 1fr 140px 150px 180px 50px; 
        padding: var(--spacing-4) 0;
        align-items: center;
        grid-template-areas: 
            'base-product-info stock-status price  add-to-basket add-favorite'
    }


    .price, .stockStatus, .addToBasket {
        display: flex;
        justify-content: center;
    }
}