.table {
    border-collapse:collapse;
    width: 100%;

    & thead {
        font-weight: var(--font-weight-semi-bold);
        font-size: var(--font-size-xs);
        background-color: var(--color-neutral-200);
    }

    & tbody {
        font-size: var(--font-size-sm);
        font-weight: var(--font-weight-light);

        & tr {
            &:nth-child(even) {
                background-color: var(--color-neutral-200);
            }
        }
    }

    & td,
    & th {
        padding: var(--spacing-2) var(--spacing-3);
        text-align: left;
        border-right: 1px solid var(--color-line);

        &:last-child {
            border: none
        }
    }
}

.srOnly {
    position:absolute;
    left:-10000px;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
 }

.cleanList {
    margin: 0;
    padding: 0;
    list-style: none;
}

.cleanButton {
    text-align: left;
    width: auto;
    appearance: none;
    border: none;
    background-color: transparent;
  
    &:focus {
      outline: none;
    }
  }

.scrollBarThin {
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-thumb {
        background: var(--color-neutral-500);
    }
    &::-webkit-scrollbar-track {
        background: var(--color-neutral-300);
    }
}

.scrollBarMedium {
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background: var(--color-neutral-500);
    }
    &::-webkit-scrollbar-track {
        background: var(--color-neutral-300);
    }

}


.nShiftWidgetRoot {
    .unifaun-checkout-option-title, 
    .unifaun-checkout-additional-fields-title, 
    .unifaun-checkout-top-addons-title, 
    .unifaun-checkout-option-price {
        color: var(--color-body);
        
    }
    
    .unifaun-checkout-narrow .unifaun-checkout-option-header0 .unifaun-checkout-option-radio {
        margin-top: 2px;
        margin-right: var(--spacing-2);
        margin-left: var(--spacing-2);
    }
    
    .unifaun-checkout-narrow .unifaun-checkout-option-header0 .unifaun-checkout-option-title,
    .unifaun-checkout-narrow .unifaun-checkout-option-header0 .unifaun-checkout-additional-fields-title,
    .unifaun-checkout-narrow .unifaun-checkout-option-header0 .unifaun-checkout-top-addons-title, 
    .unifaun-checkout-narrow .unifaun-checkout-option-header0 .unifaun-checkout-option-price {
        font-family: var(--font-family-body);
        font-size: var(--font-size-md);
        font-weight: var(--font-weight-regular);
    }
    
    .unifaun-checkout-option0:not(.unifaun-checkout-multilevel0).unifaun-checkout-selected0, 
    .unifaun-checkout-additional-fields:not(.unifaun-checkout-multilevel0).unifaun-checkout-selected0, 
    .unifaun-checkout-top-addons:not(.unifaun-checkout-multilevel0).unifaun-checkout-selected0 {
        border-color: var(--color-neutral-700);
    }
    
    .unifaun-checkout-option0 input:checked+.unifaun-checkout-option-radio-target, 
    .unifaun-checkout-additional-fields input:checked+.unifaun-checkout-option-radio-target, 
    .unifaun-checkout-top-addons input:checked+.unifaun-checkout-option-radio-target, 
    .unifaun-checkout-option0 input:checked+.unifaun-checkout-option-radio-target, 
    .unifaun-checkout-additional-fields input:checked+.unifaun-checkout-option-radio-target, 
    .unifaun-checkout-top-addons input:checked+.unifaun-checkout-option-radio-target {
        background-color: var(--color-neutral-700);
    }

    .unifaun-checkout-narrow .unifaun-checkout-option-prefix-icon img, 
    .unifaun-checkout-narrow .unifaun-checkout-option-suffix-icon img {
        max-width: 60px;
        max-height: 40px;
    }

    .unifaun-checkout-option-agents:focus, 
    .unifaun-checkout-list-input-select:focus, 
    .unifaun-checkout-text-input-input:focus {
        border-color: var(--color-neutral-700) !important;
    }
}
