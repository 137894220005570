.button {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: var(--border-radius-small);
    border-width: 1px;
    border-style: solid;
    white-space: nowrap;
    text-decoration: none;
    font-weight: var(--font-weights-regular);

    &:disabled, &.fetching {
        cursor: default;
    }

    &:focus {
        outline: 2px solid var(--color-focus);
    }
}

.content {
    pointer-events: none;
}

.iconRight {
    order: 1;
}

.flexible {
    width: 100%;
}

.linkSize {
    font-size: var(--font-size-xs);
    padding: 0;
    gap: var(--spacing-2);

    & .icon {
        width: 22px;
        height: 22px;
    }
}

.smallSize {
    font-size: var(--font-size-xs);
    height: var(--spacing-5);
    gap: 8px;

    &:not(.iconOnly) {
        padding: 0 var(--spacing-2);
    }

    &.iconOnly {
        width: var(--spacing-5);
    }

    & .icon {
        width: var(--spacing-4);
        height: var(--spacing-4);
    }
}



.mediumSize {
    font-size: var(--font-size-xs);
    height: var(--spacing-6);
    gap: 10px;

    &:not(.iconOnly) {
        padding: 0 var(--spacing-3);
    }

    &.iconOnly {
        width: var(--spacing-7);
    }

    & .icon {
        width: 22px;
        height: 22px;
    }
}

.largeSize {
    font-size: var(--font-size-sm);
    height: var(--spacing-7);
    gap: 12px;

    &:not(.iconOnly) {
        padding: 0 var(--spacing-4);
    }

    &.iconOnly {
        width: var(--spacing-7);
    }

    & .icon {
        width: 24px;
        height: 24px;
    }
}

.primaryVariant {
    color: var(--color-neutral-white);
    background-color: var(--color-neutral-900);
    border-color: var(--color-neutral-900);


    & .icon path {
        stroke: var(--color-neutral-white);
    }

    &:hover:not([disabled]) {
        background-color: var(--color-neutral-700);
        border-color: var(--color-neutral-700);
    }

    &:active:not([disabled]) {
        background-color: var(--color-neutral-black);
        border-color: var(--color-neutral-black);
    }

    &:disabled {
        background-color: var(--color-neutral-400);
        border-color: var(--color-neutral-400);
    }
}

.secondaryVariant {
    color: var(--color-neutral-black);
    background-color: var(--color-neutral-white);
    border-color: var(--color-neutral-900);


    & .icon path {
        stroke: var(--color-neutral-black);
    }

    &:hover:not([disabled]) {
        background-color: var(--color-neutral-200);
        border-color: var(--color-neutral-black);
    }

    &:active:not([disabled]) {
        border-color: var(--color-neutral-700);
    }

    &:disabled {
        color: var(--color-neutral-400);
        border-color:  var(--color-neutral-400);

        & .icon path {
            stroke:  var(--color-neutral-400);
        }
    }
}

.tertiaryVariant {
    color: var(--color-neutral-black);
    background-color: var(--color-neutral-200);
    border-color: var(--color-neutral-300);


    & .icon path {
        stroke: var(--color-neutral-black);
    }

    &:hover:not([disabled]) {
        background-color: var(--color-neutral-300);
        border-color: var(--color-neutral-400);
    }

    &:active:not([disabled]) {
        background-color: var(--color-neutral-300);
        border-color: var(--color-neutral-400);
    }

    &:disabled {
        color: var(--color-neutral-400);
        border-color:  var(--color-neutral-400);

        & .icon path {
            stroke:  var(--color-neutral-400);
        }
    }
}

.linkVariant {
    color: var(--color-neutral-900);
    background-color: transparent;
    border-radius: 0px;
    border: none;
    height: auto;

&.mediumSize {
    padding: 0;
}
    & .icon path {
        stroke: var(--color-neutral-900);
    }

    &:hover:not([disabled]) {
        color: var(--color-neutral-700);

    }

    &:active:not([disabled]) {
        color: var(--color-neutral-black);
    }

    &:disabled {
        color: var(--color-neutral-400);

        & .icon path {
            stroke: var(--color-neutral-400);
        }
    }
}

.loader {
    position: absolute;
    padding: 0;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}