.root {
    display: inline-flex;
    align-items: center;
    gap: var(--spacing-2);
    font-weight: var(--font-weight-regular);

    color: var(--color-neutral-900);


}

.link {
    composes: root;
    text-decoration: none;
    cursor: pointer;

    & .icon path {
        stroke: var(--color-neutral-900);
        stroke-width: 2px;
    }

    &:hover {
        color: var(--color-neutral-700);

        & .icon path {
            stroke: var(--color-neutral-700);
        }
    }

    &:focus {
        outline: 2px solid var(--color-focus);
    }

    &:active {
        color: var(--color-neutral-black);

        & .icon path {
            stroke: var(--color-neutral-black);
        }
    }
}

.underline {
    text-decoration: underline;
}

.inactiveLink {
    composes: root;
    color: var(--color-neutral-400);

    & .icon path {
        stroke: var(--color-neutral-400);
    }
}

.medium {
    font-size: var(--font-size-xs);

    & .icon {
        width: 16px;
        height: 16px;
    }

}

.large {
    font-size: var(--font-size-md);

    & .icon {
        width: 24px;
        height: 24px;
    }
}

.iconRight {
    order: 1;
}