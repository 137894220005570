.root {
    display: grid;
}

.leftAlign {
    justify-items: left;
}

.rightAlign {
    justify-items: right;
}

.skeletonPrice {
    margin-bottom: 7px;
}

.skeletonPriceLarge {
    margin-bottom: 10px;
}
