/* keyframes to slide open accordion items */
@keyframes openAnimation {
    from {
        height: 0;
        transform: translatey(-var(--spacing-1));
        opacity: 0.5;
        padding: 0;
    }
    to {
        height: var(--radix-accordion-content-height);
    }
}
@keyframes closeAnimation {
    from {
        height: var(--radix-accordion-content-height);
    }
    to {
        height: 0;
        transform: translatey(-var(--spacing-1));
        opacity: 0.5;
        padding: 0;
    }
}

/* each accordion row */
.accordionItem {
   margin-left: 0;
    width: 100%;
    border-top: 1px solid var(--color-neutral-300);
    border-bottom: 1px solid var(--color-neutral-300);
    display: block;
    list-style: none;
    & + & {
        border-top: none;
    }

    @media --md {
        padding-left: var(--spacing-5);
        padding-right: var(--spacing-5);
    }
}

/* button opening and closing accordion */
.accordionTrigger {
    display: grid;
    gap: var(--spacing-4);
    grid-template-columns: 1fr min-content;
    padding-left: var(--spacing-5);
    align-items: center;
    padding: var(--spacing-4, 0);
    /* Reset Button  */
    background-color: transparent;
    border: none;
    width: 100%;
    cursor: pointer;
    text-align: left;
    color: var(--color-neutral-black);
}

.accordionContent {
    width: 100%;
    padding: var(--spacing-4);

}
/* accordion section that slides open and closed */
.accordionContentContainer {
    overflow: hidden;
}
.accordionContentContainer[data-state='open'] {
    animation: openAnimation 300ms cubic-bezier(0.35, 0, 0.15, 1);
}
.accordionContentContainer[data-state='closed'] {
    animation: closeAnimation 300ms cubic-bezier(0.35, 0, 0.15, 1);

}

.accordionIcon {
    font-size: var(--font-size-4xl);
    stroke-width: 1.5px;
    transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
    width: 24px;
    height: 24px;
}
.accordionTrigger[data-state='open'] .accordionIcon {
    transform: rotate(180deg);
}
